import styles from '@styles/list-page-scss/_sortOptionsVideo.module.scss';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

type SortTabsProps = {
    containerRef?: React.RefObject<HTMLDivElement>; // Optional for scroll functionality
    tabs: { label: string; value: string }[]; // Array of tab options with label and value
    style?: string;
};

const SortTabsV2: FC<SortTabsProps> = ({ containerRef, tabs, style = '' }) => {
    const router = useRouter();
    const [activeTab, setActiveTab] = useState<number | null>(null); // Default to null instead of an empty string

    useEffect(() => {
        if (router.isReady) {
            setActiveTab(findActiveTab());
        }
    }, [router.isReady, router.query.sort]); // Watch for router readiness and the 'sort' query change

    const findActiveTab = (): number => {
        const { sort } = router.query;
        if (typeof sort === 'string') {
            const index = tabs.findIndex((tab) => tab.value === sort);
            return index !== -1 ? index : 0;
        }
        return 0;
    };

    const handleSortChange = (value: string) => {
        if (containerRef) {
            // Scroll-based functionality
            if (value === 'newest') {
                const firstNewestCard = document.querySelector('#first-newest-card');
                if (firstNewestCard) {
                    firstNewestCard.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                }
            } else if (value === 'upcoming') {
                containerRef.current?.scrollTo({
                    left: 0,
                    behavior: 'smooth'
                });
            }
        } else {
            // Query-based functionality
            const { query } = router;
            const updatedQuery = { ...query };

            // Remove unnecessary query parameters
            const paramsToRemove = ['page', 'discount', 'lowest'];
            paramsToRemove.forEach((param) => delete updatedQuery[param]);

            if (value !== 'relevance') {
                updatedQuery.sort = value;
            } else {
                delete updatedQuery.sort;
            }

            // Build query string manually
            const queryString = Object.entries(updatedQuery)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')
                .toLowerCase();

            router.push({ pathname: router.pathname, query: queryString }, undefined, { shallow: true });
        }

        setActiveTab(tabs.findIndex((tab) => tab.value === value));
    };

    if (activeTab === null) return null; // Render nothing if the router isn't ready yet

    return (
        <div className={`${styles.tabContainer} ${styles[style]}`}>
            {tabs.map((tab, index) => (
                <div
                    key={tab.value}
                    className={`${styles.tab} ${index === activeTab ? styles.activeTab : ''}`}
                    onClick={() => handleSortChange(tab.value)}
                    role="button"
                    tabIndex={0}
                    aria-label={`Select ${tab.label} tab`}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSortChange(tab.value);
                        }
                    }}>
                    <div>{tab.label}</div>
                </div>
            ))}
        </div>
    );
};

export default SortTabsV2;
