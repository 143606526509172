import styles from '@styles/common-scss/_seoContainer.module.scss';
import React, { useEffect, useRef, useState } from 'react';

interface ListItem {
    link: string;
    linkName: string;
    text: string;
}

interface SEOContainerProps {
    title: string;
    image?: string | null;
    video?: string | null;
    content: string;
    list: ListItem[];
}

export function SEOContainer({ title, image, video, content, list }: SEOContainerProps) {
    // Reference to the content div for dynamic height checking
    const contentRef = useRef<HTMLDivElement>(null);
    const boxRef = useRef<HTMLDivElement>(null);
    const headingRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    // State to manage the visibility of the "Read More" button
    const [showReadMore, setShowReadMore] = useState(false);
    // State to manage the expansion of the content
    const [isExpanded, setIsExpanded] = useState(false);
    // Check if video or image is present
    const isVideo = video !== '';
    const isImage = image !== '';

    // Effect to dynamically check the height of the content and show/hide the "Read More" button
    useEffect(() => {
        const checkHeight = () => {
            if (contentRef.current) {
                const totalHeight =
                    boxRef.current.scrollHeight <
                    headingRef.current.scrollHeight + textRef.current.scrollHeight + contentRef.current.scrollHeight + 30;
                setShowReadMore(totalHeight);
            }
        };

        // Initial check
        checkHeight();

        // Add resize listener for dynamic height checking on window resize
        window.addEventListener('resize', checkHeight);

        // Cleanup function to remove the resize listener
        return () => window.removeEventListener('resize', checkHeight);
    }, [content, list]);

    return (
        <div ref={boxRef} className={styles.container}>
            {/* Heading */}

            <div ref={headingRef}>
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.line}></div>

                {/* Render video .mp4 format*/}
                {isVideo && (
                    <div className={styles.image}>
                        <video className={styles.video} autoPlay muted loop playsInline preload="none">
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                {/* Render image format */}
                {isImage && (
                    <div className={styles.image}>
                        <img src={image} alt={title} />
                    </div>
                )}
            </div>

            {/* Expanding hook */}
            <div ref={contentRef} className={`${styles.content} ${!isExpanded ? styles.clamp : ''}`}>
                <div ref={textRef}>
                    <div className={styles.mainContent} dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>

                <div className={styles.listContainer}>
                    {list.map((item, index) => (
                        <SEOItem key={index} number={index + 1} title={item.linkName} description={item.text} link={item.link} />
                    ))}
                </div>
            </div>

            {/* Read More funciton & animation */}
            {showReadMore && (
                <button className={styles.readMore} onClick={() => setIsExpanded(!isExpanded)}>
                    <span>{isExpanded ? 'read less' : 'read more'}</span>
                    <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ transform: isExpanded ? 'rotate(180deg)' : 'none' }}>
                        <path
                            d="M6 9.44434L12 15.4443L18 9.44434"
                            stroke="#004CD5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            )}
        </div>
    );
}

// Define the structure of the SEOItem component's props
interface SEOItemProps {
    number: number;
    title: string;
    description: string;
    link: string;
}

// SEOItem component
export function SEOItem({ number, title, description, link }: SEOItemProps) {
    return (
        <div className={styles.newsItem}>
            <div className={styles.number}>
                <span>{number}</span>
            </div>
            <div className={styles.itemContent}>
                <div className={styles.text}>
                    <a href={link} className={styles.itemTitle}>
                        {title}
                    </a>
                    <span className={styles.separator}> - </span>
                    <span className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></span>
                </div>
            </div>
        </div>
    );
}

// Define the structure of the SEOBoxGrid component's props
interface DataItem {
    title: string;
    image?: string; // Optional if empty
    video?: string; // Optional if empty
    content: string;
    list: { link: string; linkName: string; text: string }[];
}

// SEOBoxGrid component
export function SEOBoxGrid({ data }: { data: DataItem[] }) {
    return (
        <div className={styles.gridContainer}>
            {data.map((item, index) => (
                <SEOContainer key={index} {...item} />
            ))}
        </div>
    );
}
