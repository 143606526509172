import Card from '@components/partials/common/card/card';
import styles from '@styles/default-page-scss/list-cards/_list.module.scss';
import { useRouter } from 'next/router';
import React from 'react';

import { CardDataArrayType } from '../../../lib/types/cardProps';
import { holidayProps } from '../../../lib/types/holidayProps';

interface Props {
    latestReviews: CardDataArrayType;
    holiday: holidayProps;
}

const LatestReviews: React.FC<Props> = ({ latestReviews, holiday }) => {
    const router = useRouter();

    return (
        <>
            {router.query.page ? '' : <h2 className={styles.titleLatest}> Latest Premium Porn Reviews</h2>}

            <section className={styles.listSection}>
                <div className={styles.allCards}>
                    <div className={styles.cardList}>
                        {latestReviews.map((card, cIndex) => (
                            <div className={styles.cardDiv} key={`latestReviewsCard-${cIndex}`}>
                                <Card site={card} holiday={holiday} index={cIndex} pageTag={'default'} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default LatestReviews;
